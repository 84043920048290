<template>
  <v-container>
    <v-row v-if="highlightedText" class="d-flex justify-center">
      <v-col class="d-flex justify-center" cols="12"> Word Lookup </v-col>
      <div v-for="lexeme in topTranslations" :key="lexeme.word">
        <v-col cols="12">{{ lexeme.word }} </v-col>
        <v-col cols="12"> {{ lexeme.pronounciation }} - {{ lexeme.english_meanings }} </v-col>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import * as functions from "@/javascript/functions"

export default {
  components: {},

  data: function () {
    return {
      translations: [],
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    highlightedText() {
      return this.$store.state.highlightedText
    },
    topTranslations() {
      return this.translations.slice(0, 4)
    },

    settings() {
      return this.$store.state.user.srs_user
    },
  },

  methods: {
    async translate(word, language) {
      let text = this.highlightedText

      this.$django_api.get(`srs/lexeme?word=${text}`).then((response) => {
        this.translatedHighlightedText = response.data
      })

      // const url = "https://text-translator2.p.rapidapi.com/translate"
      // const options = {
      //   method: "POST",
      //   headers: {
      //     "content-type": "application/x-www-form-urlencoded",
      //     "X-RapidAPI-Key": "10389bc76fmsh28fdc3b135ab675p16b7abjsne238cd59709b",
      //     "X-RapidAPI-Host": "text-translator2.p.rapidapi.com",
      //   },
      //   body: new URLSearchParams({
      //     source_language: "", //LanguageMap[this.language],
      //     target_language: "en",
      //     text: text,
      //   }),
      // }

      // try {
      //   const response = await fetch(url, options)
      //   const result = await response.text()

      //   this.translatedHighlightedText = result
      // } catch (error) {
      //   console.error(error)
      // }
    },
  },
  mounted() {},
  watch: {
    highlightedText: {
      handler(val) {
        if (val) {
          this.$django_api
            .get(`srs/lexeme?word=${val}&language=${this.settings.language}`)
            .then((response) => {
              if (response.data.length > 0) {
                this.translations = response.data
              }
            })
          // functions.translate(val, this.language).then((response) => {
          //   this.translatedHighlightedText = response
          // })
        } else {
          this.translations = []
        }
      },
    },
  },
}
</script>
